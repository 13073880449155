import { create } from "zustand";
import { TGuestStore } from "./Store.types";
import { createJSONStorage, persist } from "zustand/middleware";
import { TPhotoChallengeDone, TTeamMatchResult } from "services/types";

export const useGuestStore = create<TGuestStore>()(
  persist(
    (set) => ({
      guest: null,
      quizResult: null,
      photoChallengesDone: [],
      teamMatchResult: null,
      setGuest: (guest) => set({ guest }),
      setQuizResult: (quizResult) => set({ quizResult }),
      addPhotoChallengeDone: (result: TPhotoChallengeDone) =>
        set((state) => {
          return {
            photoChallengesDone: [...state.photoChallengesDone, result],
          };
        }),
      updatePhotoChallengeDone: (result: TPhotoChallengeDone) =>
        set((state) => {
          const index = state.photoChallengesDone.findIndex(
            (item) => item.challengeId === result.challengeId
          );

          if (index === -1) return state;

          const newPhotoChallengeDones = [...state.photoChallengesDone];
          newPhotoChallengeDones[index] = result;

          return {
            photoChallengesDone: newPhotoChallengeDones,
          };
        }),
      removePhotoChallengeDone: (challengeId: string) =>
        set((state) => {
          return {
            photoChallengesDone: state.photoChallengesDone.filter(
              (item) => item.challengeId !== challengeId
            ),
          };
        }),
      setTeamMatchResult: (teamMatchResult: TTeamMatchResult) =>
        set({ teamMatchResult }),
      clearStore: () => {
        set({
          guest: null,
          quizResult: null,
          photoChallengesDone: [],
          teamMatchResult: null,
        });
      },
    }),
    {
      name: "guest-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
