export const dateToString = (date: Date) => {
  //if date is not a Date, return date
  if (!(date instanceof Date)) return date;

  if (date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const resultDay = day < 10 ? `0${day}` : day;
    const resultMonth = month < 10 ? `0${month}` : month;

    return `${year}-${resultMonth}-${resultDay}`;
  }
  return "";
};

export const shareLink = async (url: any, text: any, logCallback: Function) => {
  // console.log("text", text);

  if (navigator.share === undefined) {
    console.debug("navigator share support not found");
    return;
  }

  await navigator.share({ url, text });

  logCallback();
};

export const base64ToFile = (
  base64: string,
  filename: string,
  mimeType: string
): File => {
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
};
