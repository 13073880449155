import ReactDOM from "react-dom";
import { useEventStore } from "store/useEventStore";

interface BaseModalProps {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

export default function BaseModal({
  children,
  onClose,
  isOpen,
}: BaseModalProps) {
  const { theme } = useEventStore();
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div
      onClick={onClose}
      id="base-modal-overlay"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-10"
    >
      <div
        id="base-modal-wrapper"
        className={`p-6 shadow-xl flex flex-col items-center card ${theme.card}`}
      >
        {children}
      </div>
    </div>,
    document.body
  );
}
