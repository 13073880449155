const message = {
  "button.home.sendFiles": "Envoyer des fichiers",

  "button.options.record": "Enregistrer maintenant",
  "button.options.upload": "Envoyer des fichiers",
  "button.options.gallery": "Voir l'album",
  "button.options.bingoGame": "Bingo",
  "button.options.photoChallengeGame": "Défi photo",
  "button.options.quizGame": "Quiz",
  "button.options.teamMatchGame": "Match d'équipe",
  "button.options.foodMenu": "Menu",
  "button.options.schedule": "Horaire",
  "button.options.feed": "Fil de la fête",
  "message.options.bingoGame": "Jouez au bingo de la fête !",
  "message.options.photoChallengeGame": "Participez au défi photo !",
  "message.options.quizGame": "Testez vos connaissances !",
  "message.options.teamMatchGame": "De quelle équipe faites-vous partie ?",
  "message.options.foodMenu": "Consultez le menu de la fête !",
  "message.options.schedule": "Consultez l'horaire de l'événement !",
  "message.options.record": "Enregistrez votre message spécial maintenant !",
  "message.options.upload":
    "Partagez les meilleures photos et vidéos de la fête !",
  "message.options.gallery": "Regardez les photos de l'événement !",
  "message.options.feed": "Suivez la chronologie de l'événement!",

  "title.notFound": "Oups, il semble que cette page n'existe pas.",
  "title.eventNotFound": "Erreur lors de l'accès à l'événement",

  "subtitle.goBackTo": "Retour à la",
  "subtitle.eventNotFound": "Événement non trouvé ou expiré.",

  "message.termsOfUse":
    "En utilisant notre système, vous acceptez nos <code>Conditions d'utilisation</code>",
  "message.termsFirstParagraph":
    "En utilisant notre système de téléchargement de photos et de vidéos, vous acceptez, en tant qu'invité de l'événement, que nous collections et téléchargeons vos données, y compris des photos, des vidéos et des informations personnelles, et que nous les mettions à la disposition de l'organisateur de l'événement.",
  "message.termsSecondParagraph":
    "Nous n'accédons pas à vos fichiers sauf si le client le demande pour fournir une assistance technique, limitée à ce qui est nécessaire.",
  "message.termsThirdParagraph":
    "La société ne partage pas de données avec des tiers et maintient la confidentialité des informations, sauf lorsque la loi l'exige.",
  "message.shareLink":
    "Pour maintenir la qualité du fichier, téléchargez des fichiers depuis la galerie de votre appareil.",

  // recorder
  "button.recorder.home": "Retour à l'écran initial",
  "button.recorder.understood": "Compris !",
  "button.recorder.close": "Fermer",
  "button.recorder.share": "Partager",
  "button.recorder.back": "Retour",

  "title.recorder.permissionsToRecordAudioVideo": "Permission d'enregistrer",
  "title.recorder.success": "Succès !",
  "title.recorder.uploading": "Envoi en cours",
  "title.recorder.alert": "Alerte !",
  "title.recorder.saving": "Enregistrement",
  "title.recorder.waiting": "En attente",
  "title.recorder.share": "Partagez !",
  "title.recorder.error": "Erreur !",
  "title.recorder.permissionCameraOrMicrophoneDenied":
    "Permission non accordée",
  "title.recorder.terms": "Conditions d'utilisation",

  "subtitle.recorder.nextScreenClickAllow":
    "Sur l'écran suivant, cliquez sur <code>Autoriser</code>.",
  "subtitle.recorder.allowReinforcement":
    "Vous devez cliquer sur <code>Autoriser</code> pour enregistrer votre message.",
  "subtitle.recorder.share": "Invitez vos amis à enregistrer aussi !",
  "subtitle.recorder.dontRefreshPage":
    "Veuillez ne pas actualiser ou fermer cette page.",
  "subtitle.recorder.status.success":
    "Votre message a été enregistré avec succès !",
  "subtitle.recorder.browserNotSupported":
    "Votre navigateur n'est pas pris en charge ou la permission n'a pas été accordée.",
  "subtitle.recorder.tryGrantPermission":
    "1. Actualisez la page et autorisez l'utilisation de la caméra.",
  "subtitle.recorder.tryAnotherBrowser":
    "2. Essayez d'utiliser un autre navigateur comme {chrome} ou {safari}.",
  "subtitle.recorder.mustLongerThan":
    "Le message doit durer plus de <code>5 secondes</code>.",
  "subtitle.recorder.offline":
    "Vous êtes hors ligne. Veuillez vérifier votre connexion.",
  "subtitle.recorder.startRecord": "Cliquez pour commencer à enregistrer.",
  "subtitle.recorder.recording": "Enregistrement...",
  "subtitle.recorder.uploadError": "Erreur lors du téléchargement de la vidéo.",
  "subtitle.recorder.tryAgain": "Vérifiez votre connexion et réessayez.",
  "subtitle.recorder.permissionCameraOrMicrophoneDenied":
    "L'utilisation de la caméra/du microphone n'a pas été autorisée.",
  "subtitle.recorder.trySafariBrowser":
    "1. Utilisez le navigateur <code>SAFARI</code>.",
  "subtitle.recorder.changeSiteSettingsUsingSafari":
    '2. Cliquez sur le bouton <code>"aA"</code> dans la barre d\'URL et sur <code>"Paramètres du site"</code>',
  "subtitle.recorder.grantCameraAndMicrophonePermissions":
    "3. <code>Autorisez l'utilisation</code> de la caméra et du microphone",
  "subtitle.recorder.refreshPage": "4. Actualisez la page",
  "subtitle.recorder.tryChromeBrowser":
    "1. Utilisez le navigateur <code>CHROME</code>",
  "subtitle.recorder.changeSiteSettingsUsingChrome":
    '2. Accédez aux paramètres du site et cliquez sur <code>"Permissions"</code>',

  "message.recorder.almostDone":
    "Presque fini ! Juste quelques secondes de plus.",
  "message.recorder.sendingMessage":
    "Nous envoyons maintenant votre message. Cela peut prendre un peu de temps car il est plein de choses formidables.",

  // uploader
  "button.uploader.send_photos": "Envoyer photos",
  "button.uploader.send_video": "Envoyer vidéo",
  "button.uploader.close": "Fermer",
  "button.uploader.share": "Partager",
  "button.uploader.back": "Retour",
  "button.uploader.send": "Envoyer",
  "button.uploader.continue": "Continuer",
  "button.uploader.ok": "OK!",

  "title.uploader.success": "Succès!",
  "title.uploader.uploading": "Téléchargement",
  "title.uploader.alert": "Alerte!",
  "title.uploader.saving": "Sauvegarde",
  "title.uploader.waiting": "En attente",
  "title.uploader.share": "Partager!",
  "title.uploader.error": "Erreur!",
  "title.uploader.upload": "Télécharger",
  "title.uploader.shareMoments": "Partagez des moments",
  "title.uploader.uploadFailure": "Échec du téléchargement",
  "title.uploader.completed": "Terminé",
  "title.uploader.terms": "Conditions d'utilisation",

  "subtitle.uploader.filesWithError":
    "Fichiers qui <code>ne seront pas envoyés</code> :",
  "subtitle.uploader.uploadHistory": "Téléchargements terminés",
  "subtitle.uploader.filesReadyToUpload": "Fichiers prêts à être téléchargés :",

  "message.uploader.uploadMaxFilesLimit":
    "Envoyez jusqu'à <code>50 photos ou 1 vidéo</code> à la fois.",
  "message.uploader.uploadLimit": "Limite maximale :",
  "message.uploader.photoSizeLimit": "<code>20Mo</code> par photo",
  "message.uploader.videoSizeLimit": "<code>200Mo</code> par vidéo",
  "message.uploader.fileSizeError": "Le fichier dépasse la taille autorisée.",
  "message.uploader.uploading":
    "Veuillez ne pas actualiser ou fermer cette page.",
  "message.uploader.uploadError": "Échec du téléchargement.",
  "message.uploader.maxFilesLimitError":
    "Sélectionnez au <code>maximum 50 photos</code>.",
  "message.uploader.notFoundFilesToUpload":
    "<code>Aucun fichier ne peut être envoyé</code>.",
  "message.uploader.checkFileSizeAndTryAgain":
    "Veuillez vérifier la <code>taille du fichier</code> et réessayer.",
  "message.uploader.uploadComplete": "Téléchargement terminé",
  "message.uploader.allFilesUploaded": "Tous les fichiers ont été envoyés",
  "message.uploader.nFilesUploaded": "{n} fichiers envoyés",
  "message.uploader.nFilesNotUploaded": "{n} fichier(s) non envoyé(s)",
  "message.uploader.noFilesUploaded": "Aucun fichier n'a été envoyé",
  "message.uploader.checkYourConnectionAndTryAgain":
    "Vérifiez votre connexion et réessayez.",
  "message.uploader.uploadVerySlow":
    "Si le téléchargement est lent, sélectionnez quelques photos à la fois",
  "message.uploader.uploadTip":
    "Pour maintenir la qualité du fichier, téléchargez des fichiers depuis la galerie de votre appareil.",

  "title.guestForm": "Votre Profil",
  "placeholder.guestForm.name": "Votre nom ou surnom",
  "message.guestForm.dataInfo":
    "Les données fournies ne sont pas obligatoires. Elles seront affichées uniquement comme une signature sur les photos et vidéos que vous envoyez.",
  "button.guestForm.takePhoto": "Prenez un selfie (optionnel)",
  "button.guestForm.submit": "Enregistrer",
  "button.guestForm.skip": "Continuer de manière anonyme",
  "message.guestForm.nameRequired": "Le nom est obligatoire",
  "message.cameraNotAvailable":
    "Impossible d'accéder à la caméra. Veuillez vous assurer que vous avez accordé les autorisations pour la caméra.",

  "title.photoChallenge": "Défi Photo",
  "subtitle.photoChallenge.tasks": "Tâches",
  "subtitle.photoChallenge.done": "Terminées",
  "message.photoChallenge.intro":
    "L'objectif est de prendre des photos comme indiqué dans chaque objectif.",
  "message.photoChallenge.todo": "Relevez un défi",
  "message.photoChallenge.done": "Défis terminés",
  "button.photoChallenge.start": "Commencer",

  "title.quiz": "Quiz",
  "subtitle.quiz.intro": "Acceptez-vous le défi?",
  "subtitle.quiz.result": "Résultat",
  "message.quiz.intro":
    "Parmi tous les invités, prouvez que vous êtes la personne qui connaît le mieux le couple!",
  "button.quiz.start": "Commencer",
  "message.quiz.result":
    "Vous avez obtenu {score} sur {totalQuestions} questions en {time} secondes",
  "message.quiz.saving": "Sauvegarde...",

  "title.teamMatch": "Match d'Équipe",
  "subtitle.teamMatch.intro": "Découvrez votre équipe",
  "subtitle.teamMatch.result": "Résultat",
  "message.teamMatch.intro":
    "Faites le test et découvrez si vous faites partie de l'équipe {teamA} ou de l'équipe {teamB} !",
  "button.teamMatch.start": "Commencer",
  "message.teamMatch.result": "Votre équipe est <code>{team}</code> !",
  "message.teamMatch.saving": "Enregistrement...",

  "feed.loading": "Chargement du flux...",
  "feed.empty": "Aucun élément dans le flux pour le moment.",
  "feed.imageAlt": "Image Téléchargée",
  "feed.anonymousGuest": "Invité anonyme",
  "feed.imageUploader.title": "Photo Téléchargée",
  "feed.imageUploader.description": "{guestName} a téléchargé une photo",
  "feed.quiz.title": "Quiz",
  "feed.quiz.description":
    "{guestName} a complété le quiz ! Résultat : {score}/{total} en {time} secondes",
  "feed.photoChallenge.title": "Défi Photo",
  "feed.photoChallenge.description":
    "{guestName} a complété le défi : {challenge}",
  "feed.teamMatch.title": "Match d'Équipe",
  "feed.teamMatch.description":
    "{guestName} a participé au Match d'Équipe. Résultat : {teamAName} {scoreA} x {scoreB} {teamBName}",
  "feed.newGuest.title": "Nouvel Invité",
  "feed.newGuest.description": "{guestName} a rejoint l'événement",
  "feed.videoRecorder.title": "Nouveau Message",
  "feed.videoRecorder.description": "{guestName} a envoyé un nouveau message",
  "feed.default.title": "Activité",
  "feed.default.description": "Une nouvelle activité s'est produite",
};

export default message;
