const message = {
  "button.home.sendFiles": "Gửi tập tin",

  "button.options.record": "Ghi âm ngay bây giờ",
  "button.options.upload": "Chọn tập tin",
  "button.options.gallery": "Xem album",
  "button.options.bingoGame": "Bingo",
  "button.options.photoChallengeGame": "Thử thách ảnh",
  "button.options.quizGame": "Quiz",
  "button.options.teamMatchGame": "Team Match",
  "button.options.foodMenu": "Thực đơn",
  "button.options.schedule": "Lịch trình",
  "button.options.feed": "Dòng sự kiện",
  "message.options.bingoGame": "Chơi bingo của bữa tiệc!",
  "message.options.photoChallengeGame": "Tham gia thử thách ảnh!",
  "message.options.quizGame": "Kiểm tra kiến thức của bạn!",
  "message.options.teamMatchGame": "Bạn thuộc đội nào?",
  "message.options.foodMenu": "Xem thực đơn của bữa tiệc!",
  "message.options.schedule": "Kiểm tra lịch trình sự kiện!",
  "message.options.record": "Ghi lại thông điệp đặc biệt của bạn ngay bây giờ!",
  "message.options.upload":
    "Chia sẻ những bức ảnh và video đẹp nhất của bữa tiệc!",
  "message.options.gallery": "Xem ảnh của sự kiện!",
  "message.options.feed": "Theo dõi dòng thời gian của sự kiện!",

  "title.notFound": "Rất tiếc, có vẻ như trang này không tồn tại.",
  "title.eventNotFound": "Lỗi truy cập sự kiện",

  "subtitle.goBackTo": "Quay lại",
  "subtitle.eventNotFound": "Sự kiện không tìm thấy hoặc đã hết hạn.",

  "message.termsOfUse":
    "Bằng cách sử dụng hệ thống của chúng tôi, bạn đồng ý với <code>Điều khoản sử dụng</code> của chúng tôi",
  "message.termsFirstParagraph":
    "Bằng cách sử dụng hệ thống tải lên ảnh và video của chúng tôi, bạn, với tư cách là khách tham dự sự kiện, đồng ý rằng chúng tôi thu thập và tải lên dữ liệu của bạn, bao gồm ảnh, video và thông tin cá nhân, đồng thời cung cấp dữ liệu đó cho người tổ chức sự kiện.",
  "message.termsSecondParagraph":
    "Chúng tôi không truy cập các tệp của bạn trừ khi được khách hàng yêu cầu cung cấp hỗ trợ kỹ thuật, giới hạn ở những gì cần thiết.",
  "message.termsThirdParagraph":
    "Công ty không chia sẻ dữ liệu với bên thứ ba và duy trì tính bảo mật thông tin, trừ khi pháp luật yêu cầu.",
  "message.shareLink":
    "Tạo một kỷ niệm đặc biệt! Nhanh chóng, dễ dàng và là một cách tuyệt vời để thể hiện tình yêu và sự quan tâm của bạn trong ngày này. Nhấp vào liên kết để bắt đầu ghi âm ngay bây giờ!",

  // recorder
  "button.recorder.understood": "Đã hiểu!",
  "button.recorder.close": "Đóng",
  "button.recorder.share": "Chia sẻ",
  "button.recorder.home": "Quay lại Màn hình chính",
  "button.recorder.back": "Quay lại",

  "title.recorder.permissionsToRecordAudioVideo": "Quyền Ghi âm",
  "title.recorder.success": "Thành công!",
  "title.recorder.uploading": "Đang tải lên",
  "title.recorder.alert": "Cảnh báo!",
  "title.recorder.saving": "Đang lưu",
  "title.recorder.waiting": "Đang chờ",
  "title.recorder.share": "Chia sẻ!",
  "title.recorder.error": "Lỗi!",
  "title.recorder.permissionCameraOrMicrophoneDenied": "Quyền không được cấp",
  "title.recorder.terms": "Điều khoản sử dụng",

  "subtitle.recorder.nextScreenClickAllow":
    "Trên màn hình tiếp theo, hãy nhấp vào <code>Cho phép</code>.",
  "subtitle.recorder.allowReinforcement":
    "Bạn cần nhấp vào <code>Cho phép</code> để ghi âm tin nhắn của bạn.",
  "subtitle.recorder.share": "Mời bạn bè của bạn cùng ghi âm!",
  "subtitle.recorder.dontRefreshPage":
    "Vui lòng không làm mới hoặc đóng trang này.",
  "subtitle.recorder.status.success":
    "Tin nhắn của bạn đã được ghi âm thành công!",
  "subtitle.recorder.browserNotSupported":
    "Trình duyệt của bạn không được hỗ trợ hoặc không được cấp quyền.",
  "subtitle.recorder.tryGrantPermission":
    "1. Làm mới trang và cho phép sử dụng máy ảnh.",
  "subtitle.recorder.tryAnotherBrowser":
    "2. Hãy thử sử dụng một trình duyệt khác như {chrome} hoặc {safari}.",
  "subtitle.recorder.mustLongerThan":
    "Tin nhắn phải dài hơn <code>5 giây</code>.",
  "subtitle.recorder.offline":
    "Bạn đang ngoại tuyến. Vui lòng kiểm tra kết nối của bạn.",
  "subtitle.recorder.startRecord": "Nhấp để bắt đầu ghi âm.",
  "subtitle.recorder.recording": "Đang ghi âm...",
  "subtitle.recorder.uploadError": "Lỗi tải lên video.",
  "subtitle.recorder.tryAgain": "Kiểm tra kết nối của bạn và thử lại.",
  "subtitle.recorder.permissionCameraOrMicrophoneDenied":
    "Không được cấp quyền máy ảnh/microphone",
  "subtitle.recorder.trySafariBrowser":
    "1. Sử dụng trình duyệt <code>SAFARI</code>",
  "subtitle.recorder.changeSiteSettingsUsingSafari":
    '2. Nhấp vào nút <code>"aA"</code> trong thanh URL và vào <code>"Cài đặt trang"</code>',
  "subtitle.recorder.grantCameraAndMicrophonePermissions":
    "3. <code>Cho phép</code> sử dụng máy ảnh và microphone",
  "subtitle.recorder.refreshPage": "4. Làm mới trang",
  "subtitle.recorder.tryChromeBrowser":
    "1. Sử dụng trình duyệt <code>CHROME</code>",
  "subtitle.recorder.changeSiteSettingsUsingChrome":
    '2. Truy cập cài đặt trang và nhấp vào <code>"Quyền"</code>',

  "message.recorder.almostDone": "Gần xong! Chỉ vài giây nữa thôi.",
  "message.recorder.sendingMessage":
    "Chia sẻ những khoảnh khắc yêu thích của bạn từ bữa tiệc!",

  // uploader
  "button.uploader.send_photos": "Gửi ảnh",
  "button.uploader.send_video": "Gửi video",
  "button.uploader.close": "Đóng",
  "button.uploader.share": "Chia sẻ",
  "button.uploader.back": "Quay lại",
  "button.uploader.send": "Gửi",
  "button.uploader.continue": "Tiếp tục",
  "button.uploader.ok": "Được rồi",

  "title.uploader.success": "Thành công!",
  "title.uploader.uploading": "Đang tải lên",
  "title.uploader.alert": "Cảnh báo!",
  "title.uploader.saving": "Đang lưu",
  "title.uploader.waiting": "Đang chờ",
  "title.uploader.share": "Chia sẻ!",
  "title.uploader.error": "Lỗi!",
  "title.uploader.upload": "Tải lên",
  "title.uploader.shareMoments": "Chia sẻ khoảnh khắc",
  "title.uploader.uploadFailure": "Không thể tải lên",
  "title.uploader.completed": "Hoàn tất",
  "title.uploader.terms": "Điều khoản sử dụng",

  "subtitle.uploader.filesWithError":
    "Các tập tin <code>sẽ không được gửi</code>:",
  "subtitle.uploader.uploadHistory": "Các tập tin đã gửi",
  "subtitle.uploader.filesReadyToUpload": "Các tập tin sẵn sàng để tải lên:",

  "message.uploader.uploadMaxFilesLimit":
    "Gửi tối đa <code>50 ảnh hoặc 1 video</code> mỗi lần.",
  "message.uploader.uploadLimit": "Giới hạn tối đa:",
  "message.uploader.photoSizeLimit": "<code>20MB</code> mỗi ảnh",
  "message.uploader.videoSizeLimit": "<code>500MB</code> mỗi video",
  "message.uploader.fileSizeError": "Tập tin vượt quá kích thước cho phép.",
  "message.uploader.uploading": "Vui lòng không làm mới hoặc đóng trang này.",
  "message.uploader.uploadError": "Lỗi tải lên tập tin.",
  "message.uploader.maxFilesLimitError": "Chọn tối đa <code>50 ảnh</code>.",
  "message.uploader.notFoundFilesToUpload":
    "<code>Không tìm thấy tập tin để tải lên.</code>",
  "message.uploader.checkTheFileSizeAndTryAgain":
    "Kiểm tra <code>kích thước tập tin</code> và thử lại.",
  "message.uploader.uploadComplete": "Tải lên hoàn tất",
  "message.uploader.allFilesUploaded": "Tất cả các tập tin đã được tải lên",
  "message.uploader.nFilesUploaded": "{n} tập tin đã được tải lên",
  "message.uploader.nFilesNotUploaded": "{n} tập tin không thể tải lên",
  "message.uploader.noFilesUploaded": "Không có tập tin nào được tải lên",
  "message.uploader.checkYourConnectionAndTryAgain":
    "Kiểm tra kết nối của bạn và thử lại.",
  "message.uploader.uploadVerySlow":
    "Nếu tải lên chậm, hãy chọn một số ảnh mỗi lần",
  "message.uploader.uploadTip":
    "Để duy trì chất lượng tập tin, hãy tải lên từ thư viện thiết bị của bạn.",

  "title.guestForm": "Hồ Sơ Của Bạn",
  "placeholder.guestForm.name": "Tên hoặc biệt danh của bạn",
  "message.guestForm.nameRequired": "Tên là bắt buộc",
  "message.guestForm.dataInfo":
    "Dữ liệu được cung cấp không bắt buộc. Chúng sẽ chỉ hiển thị như một chữ ký trên các bức ảnh và video bạn gửi.",
  "button.guestForm.takePhoto": "Chụp một bức ảnh tự sướng (tùy chọn)",
  "button.guestForm.submit": "Lưu",
  "button.guestForm.skip": "Tiếp tục ẩn danh",
  "message.cameraNotAvailable":
    "Không thể truy cập máy ảnh. Vui lòng đảm bảo rằng bạn đã cấp quyền truy cập máy ảnh.",

  "title.photoChallenge": "Thử Thách Ảnh",
  "subtitle.photoChallenge.tasks": "Nhiệm vụ",
  "subtitle.photoChallenge.done": "Hoàn thành",
  "message.photoChallenge.intro":
    "Mục tiêu là chụp ảnh theo chỉ dẫn trong mỗi mục tiêu.",
  "message.photoChallenge.todo": "Hoàn thành một thử thách",
  "message.photoChallenge.done": "Các thử thách đã hoàn thành",
  "button.photoChallenge.start": "Bắt đầu",

  "title.quiz": "Câu đố",
  "subtitle.quiz.intro": "Bạn có chấp nhận thử thách không?",
  "subtitle.quiz.result": "Kết quả",
  "message.quiz.intro":
    "Trong số tất cả các khách mời, hãy chứng minh rằng bạn là người hiểu rõ nhất về cặp đôi!",
  "button.quiz.start": "Bắt đầu",
  "message.quiz.result":
    "Bạn đã trả lời đúng {score} trong số {totalQuestions} câu hỏi trong {time} giây",
  "message.quiz.saving": "Đang lưu...",

  "title.teamMatch": "Team Match",
  "subtitle.teamMatch.intro": "Khám phá đội của bạn",
  "subtitle.teamMatch.result": "Kết quả",
  "message.teamMatch.intro":
    "Làm bài kiểm tra và tìm hiểu xem bạn thuộc đội {teamA} hay đội {teamB}!",
  "button.teamMatch.start": "Bắt đầu",
  "message.teamMatch.result": "Đội của bạn là <code>{team}</code>!",
  "message.teamMatch.saving": "Đang lưu...",

  "feed.loading": "Đang tải nguồn cấp dữ liệu...",
  "feed.empty": "Chưa có mục nào trong nguồn cấp dữ liệu.",
  "feed.imageAlt": "Hình ảnh đã tải lên",
  "feed.anonymousGuest": "Khách ẩn danh",
  "feed.imageUploader.title": "Ảnh Đã Tải Lên",
  "feed.imageUploader.description": "{guestName} đã tải lên một bức ảnh",
  "feed.quiz.title": "Câu Đố",
  "feed.quiz.description":
    "{guestName} đã hoàn thành câu đố! Kết quả: {score}/{total} trong {time} giây",
  "feed.photoChallenge.title": "Thử Thách Ảnh",
  "feed.photoChallenge.description":
    "{guestName} đã hoàn thành thử thách: {challenge}",
  "feed.teamMatch.title": "Team Match",
  "feed.teamMatch.description":
    "{guestName} đã tham gia Team Match. Kết quả: {teamAName} {scoreA} x {scoreB} {teamBName}",
  "feed.newGuest.title": "Khách Mới",
  "feed.newGuest.description": "{guestName} đã tham gia sự kiện",
  "feed.videoRecorder.title": "Tin Nhắn Mới",
  "feed.videoRecorder.description": "{guestName} đã gửi một tin nhắn mới",
  "feed.default.title": "Hoạt Động",
  "feed.default.description": "Một hoạt động mới đã xảy ra",
};

export default message;
