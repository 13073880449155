import { useLogEvent } from "hooks/useLogEvent";
import { useMemo, useRef } from "react";
import { useEventStore, useGuestStore } from "store";
import * as SpacesService from "libs/digitalocean/SpacesService";
import CdtAppPublicApiClient from "cdt-app-public-api/dist";
import { TNewUpload } from "cdt-app-public-api/dist/types";

export const useUploader = () => {
  const { event } = useEventStore();
  const { guest } = useGuestStore();
  const fileIdRef = useRef<string | null>(null);
  const { logStartUploadFile, logFinishUploadFile, logUploadFileError } =
    useLogEvent();

  const baseFileName = useMemo(() => {
    if (!event) return "";
    const randomCode = Math.random().toString(36).substring(2, 8);
    const now = new Date().toISOString();
    const base = `${event?.title}-[${event?.code}]-[${now}]_${randomCode}`;
    return base;
  }, [event]);

  const uploadFile = async (
    file: File,
    setUploadProgress: React.Dispatch<React.SetStateAction<number>>,
    tags: string[] = []
  ) => {
    try {
      const fileExt = file.name.split(".").pop() as string;
      const fileName = `${baseFileName}.${fileExt}`;

      logStartUploadFile({
        fileName: fileName,
      });

      const fileStoragePath = `uploads/${event?.id}_${event?.code}/${fileName}`;

      await SpacesService.uploadFile(file, fileStoragePath, setUploadProgress);

      let uploadData: TNewUpload = {
        eventId: event?.id as string,
        eventCode: event?.code as string,
        fileStoragePath,
        fileName,
        fileSize: file.size,
        fileType: file.type,
        fileExt,
        guestId: guest?.id,
        guestName: guest?.name,
        guestPhotoStoragePath: guest?.photoStoragePath as string,
        tags,
      };

      const cdtAppPublicApiClient = new CdtAppPublicApiClient();
      const response = await cdtAppPublicApiClient.createUpload(uploadData);

      if (!response) {
        throw new Error("uploadFile [can't create upload record]");
      }

      fileIdRef.current = response.id;

      logFinishUploadFile({
        fileName,
        fileId: fileIdRef.current as string,
        fileUrl: fileStoragePath,
      });

      setUploadProgress(-1);
    } catch (error) {
      const errorMsg = `uploadFile [${JSON.stringify(error)}]`;
      logUploadFileError({ error: errorMsg });
      throw new Error(errorMsg);
    }
  };

  return {
    uploadFile,
  };
};
