import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppLayout from "layouts/AppLayout";

import { eventLoader } from "services/events";

import Loading from "components/Loading";

import EventNotFound from "pages/EventNotFound";
import NotFound from "pages/NotFound";
import ServiceTerms from "pages/ServiceTerms";
import Home from "pages/Home";
import Record from "pages/recorder/Record";
import Upload from "pages/Upload";
import Guide from "pages/recorder/Guide";
import Share from "pages/Share";
import Options from "pages/Options";
import PhotoChallenge from "pages/games/PhotoChallenge";
import Quiz from "pages/games/Quiz";
import TeamMatch from "pages/games/TeamMatch";
import Bingo from "pages/games/Bingo";
import GuestForm from "pages/GuestForm";
import Feed from "pages/Feed";

export default function AppRouter() {
  const router = createBrowserRouter([
    {
      element: <AppLayout />,
      loader: eventLoader,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/guest-form",
          element: <GuestForm />,
        },
        {
          path: "/options",
          element: <Options />,
        },
        {
          path: "/record",
          children: [
            {
              path: "guide",
              element: <Guide />,
            },
            {
              path: ":facingMode",
              element: <Record />,
            },
          ],
        },
        {
          path: "/feed",
          element: <Feed />,
        },
        {
          path: "/upload",
          element: <Upload />,
        },
        {
          path: "/games",
          children: [
            {
              path: "bingo",
              element: <Bingo />,
            },
            {
              path: "quiz",
              element: <Quiz />,
            },
            {
              path: "photo-challenge",
              element: <PhotoChallenge />,
            },
            {
              path: "team-match",
              element: <TeamMatch />,
            },
          ],
        },
        {
          path: "/event-not-found",
          element: <EventNotFound />,
        },
        {
          path: "/share",
          element: <Share />,
        },
        {
          path: "/terms",
          element: <ServiceTerms />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<Loading />} />;
}
