import { Close, Heart, User } from "assets/svg";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore, useGuestStore } from "store";
import CdtAppPublicApi from "cdt-app-public-api/dist";
import { clarity } from "react-microsoft-clarity";
import * as SpacesService from "libs/digitalocean/SpacesService";
import { TFeedEvent } from "cdt-app-public-api/dist/types";

export default function Feed() {
  const { event, theme } = useEventStore();
  const [loading, setLoading] = useState(true);
  const [feedEvents, setFeedEvents] = useState<TFeedEvent[]>([]);
  const navigate = useNavigate();

  const fetchItemUrl = useCallback(async (feedEvent: TFeedEvent) => {
    switch (feedEvent.type) {
      case "image-uploader":
        if (!feedEvent.fileStoragePath) break;

        const fileUrl = await SpacesService.getFileUrl(
          feedEvent.fileStoragePath
        );
        feedEvent.fileUrl = fileUrl;
        break;
      case "photo-challenge-done":
        if (!feedEvent.photoStoragePath) break;

        const photoUrl = await SpacesService.getFileUrl(
          feedEvent.photoStoragePath
        );
        feedEvent.photoUrl = photoUrl;
        break;
      default:
        break;
    }

    if (feedEvent.guestPhotoStoragePath) {
      const url = await SpacesService.getFileUrl(
        feedEvent.guestPhotoStoragePath
      );
      feedEvent.guestPhotoUrl = url;
    }

    return feedEvent;
  }, []);

  const handleClose = useCallback(() => {
    navigate("/options");
  }, [navigate]);

  useEffect(() => {
    const fetchFeedData = async () => {
      if (!event?.code) {
        throw new Error("Event code not found");
      }

      try {
        setLoading(true);
        const cdtAppClient = new CdtAppPublicApi();
        const response = await cdtAppClient.getFeed(event.code as string);

        if (!response) {
          throw new Error("No feed items found");
        }

        const eventsWithUrls = await Promise.all(response.map(fetchItemUrl));

        setFeedEvents(eventsWithUrls);
      } catch (error) {
        console.error("Error fetching feed data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (event?.id) {
      fetchFeedData();
    }
  }, [event, fetchItemUrl]);

  useEffect(() => {
    if (clarity.hasStarted()) {
      clarity.setTag("feed", "viewed");
    }
  }, []);

  return (
    <div
      className={`${theme} h-full w-full flex justify-center items-center py-6 overflow-hidden overflow-y-auto`}
    >
      <div className="h-full w-full flex flex-col justify-start items-center">
        <div className="flex w-full items-center justify-between mb-8 px-4">
          <h1 className="m-0 p-0 grow text-center">
            <FormattedMessage id="title.feed" defaultMessage="Feed da Festa" />
          </h1>
          <button
            className="flex items-center text-primary"
            onClick={handleClose}
          >
            <span className="text-lg">
              <Close width={36} height={36} color="none" outline="#414141" />
            </span>
          </button>
        </div>

        <div className="w-full flex-1">
          {loading ? (
            <LoadingState />
          ) : feedEvents.length === 0 ? (
            <EmptyState />
          ) : (
            <FeedContent events={feedEvents} />
          )}
        </div>
      </div>
    </div>
  );
}

const LoadingState = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <p className="text-xl text-center">
        <FormattedMessage
          id="feed.loading"
          defaultMessage="Carregando feed..."
        />
      </p>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <p className="text-xl text-center">
        <FormattedMessage
          id="feed.empty"
          defaultMessage="Não há itens na feed ainda."
        />
      </p>
    </div>
  );
};

const FeedContent = ({ events }: { events: TFeedEvent[] }) => {
  return (
    <div className="w-full h-full overflow-hidden px-4">
      <div className="relative flex flex-col gap-4 pb-8">
        {events.map((feedEvent) => (
          <FeedEventCard key={feedEvent.id} event={feedEvent} />
        ))}
      </div>
    </div>
  );
};

type THeartButtonProps = {
  liked: boolean;
  onClick: () => void;
};

const HeartButton = (props: THeartButtonProps) => {
  const { liked, onClick } = props;

  return (
    <button
      className={`flex items-center justify-center p-0 m-0`}
      onClick={onClick}
    >
      {liked ? (
        <Heart width={24} height={24} color="#F76C6F" outline="#F76C6F" />
      ) : (
        <Heart width={24} height={24} color="none" outline="#414141" />
      )}
    </button>
  );
};

const FeedEventCard = ({ event: feedEvent }: { event: TFeedEvent }) => {
  const intl = useIntl();
  const { theme } = useEventStore();
  const { guest } = useGuestStore();
  const anonymousGuest = intl.formatMessage({
    id: "feed.anonymousGuest",
    defaultMessage: "Convidado anônimo",
  });

  const [likedByGuest, setLikedByGuest] = useState(false);
  const likeInitialized = useRef(false);

  const getFeedDetails = () => {
    switch (feedEvent.type) {
      case "image-uploader":
        return {
          title: (
            <FormattedMessage
              id="feed.imageUploader.title"
              defaultMessage="Foto Enviada"
            />
          ),
          description: (
            <FormattedMessage
              id="feed.imageUploader.description"
              defaultMessage="{guestName} enviou uma foto"
              values={{ guestName: feedEvent.guestName || anonymousGuest }}
            />
          ),
        };
      case "quiz-result":
        return {
          title: (
            <FormattedMessage id="feed.quiz.title" defaultMessage="Quiz" />
          ),
          description: (
            <FormattedMessage
              id="feed.quiz.description"
              defaultMessage="{guestName} completou o quiz! Resultado: {score}/{total} em {time} segundos"
              values={{
                guestName: feedEvent.guestName || anonymousGuest,
                score: feedEvent.score,
                total: feedEvent.totalQuestions,
                time: feedEvent.timeSpentInSeconds,
              }}
            />
          ),
        };
      case "photo-challenge-done":
        return {
          title: (
            <FormattedMessage
              id="feed.photoChallenge.title"
              defaultMessage="Foto Desafio"
            />
          ),
          description: (
            <FormattedMessage
              id="feed.photoChallenge.description"
              defaultMessage="{guestName} completou o desafio: {challenge}"
              values={{
                guestName: feedEvent.guestName || anonymousGuest,
                challenge: feedEvent.challengeText,
              }}
            />
          ),
        };
      case "team-match-result":
        return {
          title: (
            <FormattedMessage
              id="feed.teamMatch.title"
              defaultMessage="Team Match"
            />
          ),
          description: (
            <FormattedMessage
              id="feed.teamMatch.description"
              defaultMessage="{guestName} participou do Team Match. Resultado: {teamAName} {scoreA} x {scoreB} {teamBName}"
              values={{
                guestName: feedEvent.guestName || anonymousGuest,
                teamAName: feedEvent.teamAName,
                teamBName: feedEvent.teamBName,
                scoreA: feedEvent.teamAScore,
                scoreB: feedEvent.teamBScore,
              }}
            />
          ),
        };
      case "new-guest":
        return {
          title: (
            <FormattedMessage
              id="feed.newGuest.title"
              defaultMessage="Novo Convidado"
            />
          ),
          description: (
            <FormattedMessage
              id="feed.newGuest.description"
              defaultMessage="{guestName} se juntou ao evento"
              values={{ guestName: feedEvent.guestName || anonymousGuest }}
            />
          ),
        };
      case "video-recorder":
        return {
          title: (
            <FormattedMessage
              id="feed.videoRecorder.title"
              defaultMessage="Nova Mensagem"
            />
          ),
          description: (
            <FormattedMessage
              id="feed.videoRecorder.description"
              defaultMessage="{guestName} enviou uma nova mensagem"
              values={{ guestName: feedEvent.guestName || anonymousGuest }}
            />
          ),
        };
      case "video-uploader":
        return {
          title: (
            <FormattedMessage
              id="feed.videoRecorder.title"
              defaultMessage="Nova Mensagem"
            />
          ),
          description: (
            <FormattedMessage
              id="feed.videoRecorder.description"
              defaultMessage="{guestName} enviou uma nova mensagem"
              values={{ guestName: feedEvent.guestName || anonymousGuest }}
            />
          ),
        };
      default:
        return {
          title: (
            <FormattedMessage
              id="feed.default.title"
              defaultMessage="Atividade"
            />
          ),
          description: (
            <FormattedMessage
              id="feed.default.description"
              defaultMessage="Uma nova atividade ocorreu"
            />
          ),
        };
    }
  };

  const { title, description } = getFeedDetails();

  let imageUrl, imageAlt;

  switch (feedEvent.type) {
    case "image-uploader":
      imageUrl = feedEvent.fileUrl || undefined;
      imageAlt = intl.formatMessage({
        id: "feed.imageAlt",
        defaultMessage: "Imagem Enviada",
      });
      break;
    case "photo-challenge-done":
      imageUrl = feedEvent.photoUrl || undefined;
      imageAlt = feedEvent.challengeText;
      break;
    default:
      imageUrl = undefined;
      break;
  }

  const handleLikeToggle = async () => {
    if (!guest) return;

    const liked = !likedByGuest;
    setLikedByGuest(liked);

    const cdtAppClient = new CdtAppPublicApi();
    const params = {
      guestId: guest.id as string,
      guestName: guest.name,
    };

    if (liked) {
      feedEvent.likes.push(guest.id as string);
      feedEvent.totalLikes += 1;

      await cdtAppClient.feedEventLike(
        feedEvent.eventCode,
        feedEvent.id,
        params
      );
    } else {
      const likeIndex = feedEvent.likes.indexOf(guest.id as string);

      if (likeIndex > -1) {
        feedEvent.likes.splice(likeIndex, 1);
        feedEvent.totalLikes -= 1;
      }

      await cdtAppClient.feedEventDislike(
        feedEvent.eventCode,
        feedEvent.id,
        params
      );
    }
  };

  useEffect(() => {
    if (!likeInitialized.current && guest && feedEvent) {
      const liked = feedEvent.likes.includes(guest.id as string);
      setLikedByGuest(liked);

      likeInitialized.current = true;
    }
  }, [likeInitialized, feedEvent, guest]);

  return (
    <div className={`${theme.card} card w-full p-6 relative space-y-4`}>
      <h3 className="text-xl font-medium mb-2">{title}</h3>
      <div className="flex gap-4 items-center">
        <div className="w-14 h-14 grow-0 shrink-0 rounded-full overflow-hidden border-2 border-gray-200">
          {feedEvent.guestPhotoUrl ? (
            <img
              src={feedEvent.guestPhotoUrl}
              alt={feedEvent.guestName || "Guest"}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <User width={24} height={24} color="none" outline="#414141" />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <h3 className="text-lg text-left font-century-gothic-bold">
            {feedEvent.guestName ? feedEvent.guestName : anonymousGuest}
          </h3>
          <p className="text-xs text-gray-500 text-left">
            {
              <FormattedDate
                value={feedEvent.createdAt}
                month="short"
                day="numeric"
              />
            }{" "}
            {<FormattedTime value={feedEvent.createdAt} />}
          </p>
        </div>
      </div>

      {imageUrl && (
        <div className="mb-4">
          <img
            src={imageUrl}
            alt={imageAlt}
            className="w-full h-auto rounded-lg"
          />
        </div>
      )}

      <div className="flex gap-2 items-end">
        <div className="flex-1">
          <h3 className="text-lg text-left font-century-gothic">
            {description}
          </h3>
        </div>

        <div className="flex items-center gap-1 justify-end">
          <HeartButton liked={likedByGuest} onClick={handleLikeToggle} />

          {feedEvent.totalLikes > 0 && (
            <p className="text-sm text-gray-500 text-left">
              {feedEvent.totalLikes}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
