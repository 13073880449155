import { Check, Close, RefreshRight, Sync } from "assets/svg";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useEventStore } from "store/useEventStore";
import { useIntl } from "react-intl";
import clsx from "clsx";

type TPhotoTakerProps = {
  children: React.ReactNode;
  width?: number;
  height?: number;
  facingMode?: "environment" | "user";
  canSwitchCamera?: boolean;
  startButtonClassNames?: string;
  photoFrameClassNames?: string;
  onPhotoTaken?: (photo: string) => void;
  onPhotoConfirmation?: (photo: string) => void;
} & React.HTMLAttributes<HTMLButtonElement>;

export default function PhotoTaker(props: TPhotoTakerProps) {
  const {
    children,
    width = 1280,
    height = 1920,
    facingMode = "environment",
    canSwitchCamera = false,
    startButtonClassNames = "",
    photoFrameClassNames = "",
    onPhotoTaken,
    onPhotoConfirmation,
    ...rest
  } = props;
  const [isStreaming, setIsStreaming] = useState(false);
  const [photo, setPhoto] = useState<string | null>(null);
  const [camera, setCamera] = useState<"environment" | "user">(facingMode);
  const showConfirmButton = useRef(true);

  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const { theme } = useEventStore();
  const intl = useIntl();

  const startCamera = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: camera,
          width: { ideal: width, min: 720, max: 1920 },
          height: { ideal: height, min: 720, max: 1920 },
        }, // Use back camera
        audio: false,
      });

      // First set streaming to true so the video element renders
      setIsStreaming(true);

      // Then wait for the video element
      const maxAttempts = 10;
      let attempts = 0;

      const waitForVideo = () => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          streamRef.current = stream;
          setPhoto(null);
        } else if (attempts < maxAttempts) {
          attempts++;
          setTimeout(waitForVideo, 100);
        } else {
          setIsStreaming(false); // Reset streaming state if we fail
          throw new Error("Video element not found after multiple attempts");
        }
      };

      waitForVideo();
    } catch (err) {
      console.error("Error accessing camera:", err);
      setIsStreaming(false); // Make sure to reset streaming state on error
      alert(
        intl.formatMessage({
          id: "message.cameraNotAvailable",
          defaultMessage:
            "Não foi possível acessar a câmera. Por favor, certifique-se de que você concedeu permissões para a câmera.",
        })
      );
    }
  }, [camera, height, width, intl]);

  const stopCamera = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
      setIsStreaming(false);
    }
  }, []);

  const closeCamera = useCallback(() => {
    stopCamera();
    setPhoto(null);
    setIsStreaming(false);
  }, [stopCamera]);

  const takePhoto = useCallback(() => {
    if (!videoRef.current) return;

    const canvas = document.createElement("canvas");
    canvas.width = width ? width : videoRef.current.videoWidth;
    canvas.height = height ? height : videoRef.current.videoHeight;

    const context = canvas.getContext("2d");
    if (context) {
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const photoData = canvas.toDataURL("image/jpeg");
      setPhoto(photoData);
      stopCamera();
      showConfirmButton.current = true;
    }
  }, [width, height, stopCamera]);

  const switchCamera = useCallback(() => {
    setCamera(camera === "environment" ? "user" : "environment");
  }, [camera]);

  const handlePhotoConfirm = () => {
    stopCamera();
    showConfirmButton.current = false;

    if (!photo || !onPhotoConfirmation) return;

    onPhotoConfirmation(photo);
  };

  useEffect(() => {
    if (photo && onPhotoTaken) onPhotoTaken(photo);
  }, [onPhotoTaken, photo]);

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, [stopCamera]);

  return (
    <>
      {!isStreaming && !photo && (
        <button
          type="button"
          onClick={startCamera}
          className={clsx(theme.button, startButtonClassNames)}
          {...rest}
        >
          {children}
        </button>
      )}

      {isStreaming && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 z-50">
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className={`absolute inset-0 my-auto
              ${facingMode === "user" && "-scale-x-100"}
              ${height ? `h-[${height}px]` : "h-full"}
              ${width ? `w-[${width}px]` : "w-full"}
            `}
          />
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 outline outline-4 outline-white rounded-full h-16 w-16 p-0.5">
            <button
              type="button"
              onClick={takePhoto}
              className="w-full h-full bg-red-600 border-none rounded-full p-0 flex items-center justify-center"
            />
          </div>
          {canSwitchCamera && (
            <div className="absolute bottom-10 right-8 p-2">
              <button type="button" onClick={switchCamera} className="hidden">
                <RefreshRight
                  width={30}
                  height={30}
                  color="none"
                  outline="#fff"
                />
              </button>
            </div>
          )}
          <div className="absolute top-6 right-4 p-2">
            <button type="button" onClick={closeCamera} className="">
              <Close width={36} height={36} color="none" outline="#fff" />
            </button>
          </div>
        </div>
      )}

      {photo && (
        <div
          className={
            photoFrameClassNames
              ? photoFrameClassNames
              : "flex flex-col items-center gap-4 relative"
          }
        >
          <img
            src={photo}
            alt="Profile"
            className={`w-fit h-fit object-contain rounded-lg ${
              camera === "user" && "-scale-x-100"
            }`}
          />
          <div className="absolute bottom-0 w-full bg-white/40 flex justify-between gap-2 p-3">
            <button
              onClick={() => {
                setPhoto(null);
                startCamera();
              }}
              className={`${theme.buttonSecondary} text-white px-4 py-2 rounded`}
            >
              <Sync width={24} height={24} color="none" outline="#F76C6F" />
            </button>
            {onPhotoConfirmation && showConfirmButton.current && (
              <button
                onClick={handlePhotoConfirm}
                className={`${theme.button} text-white px-4 py-2 rounded`}
              >
                <Check width={24} height={24} color="none" outline="white" />
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
