import { CameraOn, Check, Close, CloudUpload } from "assets/svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useEventStore, useGuestStore } from "store";
import * as SpacesService from "libs/digitalocean/SpacesService";
import { TPhotoChallenge, TPhotoChallengeDone } from "services/types";
import CdtAppPublicApi from "cdt-app-public-api/dist";
import { clarity } from "react-microsoft-clarity";

export default function PhotoChallenge() {
  const { theme } = useEventStore();
  const [step, setStep] = useState<"intro" | "game">("intro");

  const onGoToIntro = () => {
    setStep("intro");
  };

  useEffect(() => {
    if (clarity.hasStarted()) {
      clarity.setTag("photo_challenge", "viewed");
    }
  }, []);

  return (
    <div
      className={`${theme} w-full flex justify-center items-center px-5 py-10`}
    >
      <div className="w-full flex flex-col justify-start items-center">
        {/* Header */}
        <div className="flex w-full items-center justify-between mb-16">
          <h1 className="m-0 p-0 grow text-center">
            <FormattedMessage
              id="title.photoChallenge"
              defaultMessage="Foto Desafio"
            />
          </h1>
          {step !== "intro" && (
            <button
              className="flex items-center text-primary"
              onClick={onGoToIntro}
            >
              <span className="text-lg">
                <Close width={36} height={36} color="none" outline="#414141" />
              </span>
            </button>
          )}
        </div>
        {step === "intro" && <Intro onStartClick={() => setStep("game")} />}
        {step === "game" && <Game />}
      </div>
    </div>
  );
}

const Intro = ({ onStartClick }: { onStartClick: () => void }) => {
  const { theme, event } = useEventStore();

  const onBackHome = useCallback(() => {
    window.location.href = `/?code=${event?.code}`;
  }, [event?.code]);

  return (
    <div className="flex flex-col justify-center items-center gap-16">
      <p className="text-lg text-gray-500">
        <FormattedMessage
          id="message.photoChallenge.intro"
          defaultMessage="O objetivo é tirar fotos conforme indicado em cada objetivo."
        />
      </p>
      <div className="w-full flex flex-col justify-center items-center gap-8">
        <button
          onClick={onStartClick}
          className={`w-full px-4 py-3 ${theme.button}`}
        >
          <span className="text-2xl text-center w-full">
            <FormattedMessage
              id="button.photoChallenge.start"
              defaultMessage="Iniciar"
            />
          </span>
        </button>
        <button
          className="py-3 px-10 underline text-lg"
          type="button"
          onClick={onBackHome}
        >
          <FormattedMessage
            id="button.recorder.home"
            defaultMessage="Retornar à tela inicial"
          />
        </button>
      </div>
    </div>
  );
};

const Game = () => {
  const { event } = useEventStore();
  const {
    guest,
    photoChallengesDone,
    addPhotoChallengeDone,
    updatePhotoChallengeDone,
    removePhotoChallengeDone,
  } = useGuestStore();
  const [
    photoChallengesDoneWithPublicUrl,
    setPhotoChallengeDonesWithPublicUrl,
  ] = useState<Record<string, TPhotoChallengeDone>>({});

  const guestId = useMemo(
    () => guest?.id || Math.random().toString(36).substring(2, 10),
    [guest]
  );

  const handlePhotoInput = useCallback(
    async (photoChallenge: TPhotoChallenge, photoFile: File) => {
      if (!event) return;

      try {
        const photoStoragePath = `photo-challenges/${event.id}/${guestId}/${photoChallenge.id}.jpg`;

        const newPhotoChallengeDone: TPhotoChallengeDone = {
          eventId: event.id as string,
          eventCode: event.code,
          uploadId: "",
          guestId,
          guestName: guest?.name as string,
          guestPhotoStoragePath: guest?.photoStoragePath as string,
          challengeId: photoChallenge.id,
          challengeText: photoChallenge.text,
          photoStoragePath,
          createdAt: new Date().toISOString(),
        };

        addPhotoChallengeDone(newPhotoChallengeDone);

        await SpacesService.uploadFile(photoFile, photoStoragePath);

        const cdtAppPublicApi = new CdtAppPublicApi();

        const newUpload = {
          eventId: event.id as string,
          eventCode: event.code,
          fileStoragePath: photoStoragePath,
          fileName: photoFile.name,
          fileSize: photoFile.size,
          fileType: photoFile.type,
          fileExt: "jpg",
          guestId,
          guestName: guest?.name as string,
          guestPhotoStoragePath: guest?.photoStoragePath as string,
          tags: ["photo-challenge", photoChallenge.id],
        };

        const uploadResponse = await cdtAppPublicApi.createUpload(newUpload);

        console.log("uploadResponse", { newUpload, uploadResponse });

        if (!uploadResponse) {
          throw new Error("Upload failed");
        }

        newPhotoChallengeDone.uploadId = uploadResponse.id;

        const photoChallengeResultSaved =
          await cdtAppPublicApi.createPhotoChallenge(newPhotoChallengeDone);

        if (!photoChallengeResultSaved) {
          throw new Error("Photo challenge result failed");
        }

        updatePhotoChallengeDone(photoChallengeResultSaved);
      } catch (error) {
        console.error("Error handling photo confirmation: ", error);

        removePhotoChallengeDone(photoChallenge.id);
      }

      if (clarity.hasStarted()) {
        clarity.setTag("photo_challenge", "used");
      }
    },
    [
      event,
      guest,
      guestId,
      addPhotoChallengeDone,
      updatePhotoChallengeDone,
      removePhotoChallengeDone,
    ]
  );

  useEffect(() => {
    const fetchPublicUrl = async () => {
      const results = await Promise.all(
        photoChallengesDone.map(async (result) => {
          if (result.photoUrl || !result.uploadId) return result;

          const photoUrl = await SpacesService.getFileUrl(
            result.photoStoragePath
          );

          return { ...result, photoUrl };
        })
      );

      const resultsMap = results.reduce((acc, result) => {
        acc[result.challengeId] = result;
        return acc;
      }, {} as Record<string, TPhotoChallengeDone>);

      setPhotoChallengeDonesWithPublicUrl(resultsMap);
    };

    fetchPublicUrl();
  }, [setPhotoChallengeDonesWithPublicUrl, photoChallengesDone]);

  return (
    <div className="w-full flex flex-col items-start gap-8">
      <div className="w-full flex flex-col justify-start items-start gap-4">
        <h2 className="text-xl font-bold">
          <FormattedMessage
            id="subtitle.photoChallenge.tasks"
            defaultMessage="Tarefas"
          />
        </h2>
        <div className="w-full flex flex-col justify-start items-start gap-2">
          {event?.photoChallenge?.length === photoChallengesDone.length && (
            <div className={`w-full p-3`}>
              <span className="text-gray-500">
                <FormattedMessage
                  id="message.photoChallenge.done"
                  defaultMessage="Desafios finalizados"
                />
              </span>
            </div>
          )}

          {event?.photoChallenge?.map((item) => {
            const result = photoChallengesDoneWithPublicUrl[item.id];

            if (result) {
              return <ChallengeDoneCard key={result.id} result={result} />;
            }

            return (
              <ChallengeToDoCard
                key={item.id}
                photoChallenge={item}
                onPhotoInput={handlePhotoInput}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

type ChallengeToDoProps = {
  photoChallenge: TPhotoChallenge;
  onPhotoInput: (photoChallenge: TPhotoChallenge, photoFile: File) => void;
};

const ChallengeToDoCard = (props: ChallengeToDoProps) => {
  const { photoChallenge, onPhotoInput } = props;
  const { theme } = useEventStore();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleInputFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const file = e.target.files?.[0];

    console.log("file", file);

    if (!file) return;

    console.log("onPhotoInput called", photoChallenge, file);

    onPhotoInput(photoChallenge, file);
  };

  return (
    <div
      className={`w-full flex gap-2 card min-h-24 ${theme.card} cursor-pointer`}
      onClick={handleClick}
    >
      <input
        type="file"
        accept="image/png,image/jpeg,image/jpg,image/heic"
        className="hidden"
        ref={hiddenFileInput}
        onChange={handleInputFileChange}
      />

      <div className="w-full flex items-center gap-2 p-2 min-h-24">
        <div className="flex items-center justify-center border border-gray-300 rounded-xl aspect-square h-24 w-24 min-h-24 min-w-24 grow-0 shrink-0">
          <CameraOn width={36} height={36} color="none" outline="#000000" />
        </div>

        <span className="text-center text-gray-500 grow">
          {photoChallenge.text}
        </span>

        <div className="flex items-center grow-0 shrink-0 px-1">
          <span className="w-6 h-6 border-2 border-gray-300 rounded-xl"></span>
        </div>
      </div>
    </div>
  );
};

type ChallengeDoneProps = {
  result: TPhotoChallengeDone;
};

const ChallengeDoneCard = (props: ChallengeDoneProps) => {
  const { result } = props;
  const { theme } = useEventStore();

  return (
    <div key={result.id} className={`w-full card ${theme.card}`}>
      <div className="w-full flex items-center gap-2 p-2 min-h-24">
        {result.photoUrl ? (
          <div className="h-24 aspect-square relative overflow-hidden rounded-lg ml-auto grow-0 shrink-0">
            <img
              src={result.photoUrl}
              alt="Prova do desafio"
              className="absolute w-full h-full object-cover"
            />
          </div>
        ) : (
          <div className="p-2 animate-ping min-h-24 min-w-24 flex items-center justify-center grow-0 shrink-0">
            <CloudUpload
              width={24}
              height={24}
              color="none"
              outline="#656565"
            />
          </div>
        )}
        <span className="text-center text-gray-500 grow">
          {result.challengeText}
        </span>
        <div className="grow-0 shrink-0 px-1">
          <Check width={24} height={24} color="none" outline="#4CAF50" />
        </div>
      </div>
    </div>
  );
};
