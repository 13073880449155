const message = {
  "button.home.sendFiles": "Dateien senden",

  "button.options.record": "Jetzt aufnehmen",
  "button.options.upload": "Dateien auswählen",
  "button.options.gallery": "Album ansehen",
  "button.options.bingoGame": "Bingo",
  "button.options.photoChallengeGame": "Foto-Herausforderung",
  "button.options.quizGame": "Quiz",
  "button.options.teamMatchGame": "Team-Match",
  "button.options.foodMenu": "Speisekarte",
  "button.options.schedule": "Zeitplan",
  "button.options.feed": "Feed der Party",
  "message.options.bingoGame": "Spielen Sie das Party-Bingo!",
  "message.options.photoChallengeGame":
    "Nehmen Sie an der Foto-Herausforderung teil!",
  "message.options.quizGame": "Testen Sie Ihr Wissen!",
  "message.options.teamMatchGame": "Zu welchem Team gehören Sie?",
  "message.options.foodMenu": "Sehen Sie sich die Speisekarte der Party an!",
  "message.options.schedule": "Überprüfen Sie den Veranstaltungsplan!",
  "message.options.record": "Nehmen Sie jetzt Ihre spezielle Nachricht auf!",
  "message.options.upload": "Teilen Sie die besten Fotos und Videos der Party!",
  "message.options.gallery": "Sehen Sie sich die Fotos der Veranstaltung an!",
  "message.options.feed": "Verfolgen Sie die Timeline des Events!",

  "title.notFound": "Ups, diese Seite scheint nicht zu existieren.",
  "title.eventNotFound": "Fehler beim Zugriff auf die Veranstaltung",

  "subtitle.goBackTo": "Zurück zu",
  "subtitle.eventNotFound": "Veranstaltung nicht gefunden oder abgelaufen.",

  "message.termsOfUse":
    "Durch die Nutzung unseres Systems stimmen Sie unseren <code>Nutzungsbedingungen</code> zu",
  "message.termsFirstParagraph":
    "Durch die Nutzung unseres Foto- und Video-Upload-Systems stimmen Sie als Gast der Veranstaltung zu, dass wir Ihre Daten, einschließlich Fotos, Videos und persönlicher Informationen, sammeln und an den Gastgeber der Veranstaltung weitergeben.",
  "message.termsSecondParagraph":
    "Wir greifen nur auf Ihre Dateien zu, wenn dies vom Kunden zur technischen Unterstützung angefordert wird, und beschränken uns auf das Notwendige.",
  "message.termsThirdParagraph":
    "Das Unternehmen teilt die Daten nicht mit Dritten und wahrt die Vertraulichkeit der Informationen, es sei denn, dies ist gesetzlich vorgeschrieben.",
  "message.shareLink": "Teilen Sie Ihre Lieblingsmomente der Party!",

  // recorder
  "button.recorder.understood": "Verstanden!",
  "button.recorder.close": "Schließen",
  "button.recorder.share": "Teilen",
  "button.recorder.home": "Zur Startseite zurückkehren",
  "button.recorder.back": "Zurück",

  "title.recorder.permissionsToRecordAudioVideo": "Erlaubnis zur Aufnahme",
  "title.recorder.success": "Erfolg!",
  "title.recorder.uploading": "Hochladen",
  "title.recorder.alert": "Warnung!",
  "title.recorder.saving": "Speichern",
  "title.recorder.waiting": "Warten",
  "title.recorder.share": "Teilen!",
  "title.recorder.error": "Fehler!",
  "title.recorder.permissionCameraOrMicrophoneDenied":
    "Erlaubnis nicht erteilt",
  "title.recorder.terms": "Nutzungsbedingungen",

  "subtitle.recorder.nextScreenClickAllow":
    "Klicken Sie im nächsten Bildschirm auf <code>Erlauben</code>.",
  "subtitle.recorder.allowReinforcement":
    "Sie müssen auf <code>Erlauben</code> klicken, um Ihre Nachricht aufzunehmen.",
  "subtitle.recorder.share": "Laden Sie Ihre Freunde ein, auch aufzunehmen!",
  "subtitle.recorder.dontRefreshPage":
    "Bitte aktualisieren oder schließen Sie diese Seite nicht.",
  "subtitle.recorder.status.success":
    "Ihre Nachricht wurde erfolgreich aufgenommen!",
  "subtitle.recorder.browserNotSupported":
    "Ihr Browser wird nicht unterstützt oder die Erlaubnis wurde nicht erteilt.",
  "subtitle.recorder.tryGrantPermission":
    "1. Aktualisieren Sie die Seite und erlauben Sie die Nutzung der Kamera.",
  "subtitle.recorder.tryAnotherBrowser":
    "2. Versuchen Sie, einen anderen Browser wie {chrome} oder {safari} zu verwenden.",
  "subtitle.recorder.mustLongerThan":
    "Die Nachricht muss länger als <code>5 Sekunden</code> sein.",
  "subtitle.recorder.offline":
    "Sie sind offline. Bitte überprüfen Sie Ihre Verbindung.",
  "subtitle.recorder.startRecord": "Klicken Sie, um die Aufnahme zu starten.",
  "subtitle.recorder.recording": "Aufnahme läuft...",
  "subtitle.recorder.uploadError": "Fehler beim Hochladen des Videos.",
  "subtitle.recorder.tryAgain":
    "Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.",
  "subtitle.recorder.permissionCameraOrMicrophoneDenied":
    "Die Nutzung der Kamera/Mikrofons wurde nicht erlaubt.",
  "subtitle.recorder.trySafariBrowser":
    "1. Verwenden Sie den Browser <code>SAFARI</code>.",
  "subtitle.recorder.changeSiteSettingsUsingSafari":
    '2. Klicken Sie auf die Schaltfläche <code>"aA"</code> in der URL-Leiste und auf <code>"Website-Einstellungen"</code>',
  "subtitle.recorder.grantCameraAndMicrophonePermissions":
    "3. <code>Erlauben Sie die Nutzung</code> der Kamera und des Mikrofons",
  "subtitle.recorder.refreshPage": "4. Aktualisieren Sie die Seite",
  "subtitle.recorder.tryChromeBrowser":
    "1. Verwenden Sie den Browser <code>CHROME</code>",
  "subtitle.recorder.changeSiteSettingsUsingChrome":
    '2. Gehen Sie zu den Website-Einstellungen und klicken Sie auf <code>"Berechtigungen"</code>',

  "message.recorder.almostDone": "Fast fertig! Nur noch ein paar Sekunden.",
  "message.recorder.sendingMessage":
    "Wir senden jetzt Ihre Nachricht. Es kann eine Weile dauern, weil sie voller cooler Sachen ist.",

  // uploader
  "button.uploader.send_photos": "Fotos senden",
  "button.uploader.send_video": "Video senden",
  "button.uploader.close": "Schließen",
  "button.uploader.share": "Teilen",
  "button.uploader.back": "Zurück",
  "button.uploader.send": "Senden",
  "button.uploader.continue": "Fortsetzen",
  "button.uploader.ok": "OK!",

  "title.uploader.success": "Erfolg!",
  "title.uploader.uploading": "Hochladen",
  "title.uploader.alert": "Warnung!",
  "title.uploader.saving": "Speichern",
  "title.uploader.waiting": "Warten",
  "title.uploader.share": "Teilen!",
  "title.uploader.error": "Fehler!",
  "title.uploader.upload": "Hochladen",
  "title.uploader.shareMoments": "Momente teilen",
  "title.uploader.uploadFailure": "Upload fehlgeschlagen",
  "title.uploader.completed": "Abgeschlossen",
  "title.uploader.terms": "Nutzungsbedingungen",

  "subtitle.uploader.filesWithError":
    "Dateien, die <code>nicht hochgeladen werden</code>:",
  "subtitle.uploader.uploadHistory": "Abgeschlossene Uploads",
  "subtitle.uploader.filesReadyToUpload": "Dateien bereit zum Hochladen:",

  "message.uploader.uploadMaxFilesLimit":
    "Laden Sie bis zu <code>50 Fotos oder 1 Video</code> gleichzeitig hoch.",
  "message.uploader.uploadLimit": "Maximale Grenze:",
  "message.uploader.photoSizeLimit": "<code>20MB</code> pro Foto",
  "message.uploader.videoSizeLimit": "<code>500MB</code> pro Video",
  "message.uploader.fileSizeError": "Datei überschreitet die zulässige Größe.",
  "message.uploader.uploading":
    "Bitte aktualisieren oder schließen Sie diese Seite nicht.",
  "message.uploader.uploadError": "Fehler beim Hochladen.",
  "message.uploader.maxFilesLimitError":
    "Wählen Sie maximal <code>50 Fotos</code> aus.",
  "message.uploader.uploader.notFoundFilesToUpload":
    "<code>Keine Dateien können hochgeladen werden</code>.",
  "message.uploader.checkFileSizeAndTryAgain":
    "Bitte überprüfen Sie die <code>Dateigröße</code> und versuchen Sie es erneut.",
  "message.uploader.uploadComplete": "Upload abgeschlossen",
  "message.uploader.allFilesUploaded": "Alle Dateien wurden hochgeladen",
  "message.uploader.nFilesUploaded": "{n} Dateien hochgeladen",
  "message.uploader.nFilesNotUploaded":
    "{n} Datei(en) wurden nicht hochgeladen",
  "message.uploader.noFilesUploaded": "Keine Dateien wurden hochgeladen",
  "message.uploader.checkYourConnectionAndTryAgain":
    "Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.",
  "message.uploader.uploadVerySlow":
    "Wenn der Upload langsam ist, wählen Sie wenige Fotos gleichzeitig aus",
  "message.uploader.uploadTip":
    "Um die Qualität der Datei zu erhalten, laden Sie Dateien aus der Galerie Ihres Geräts hoch.",

  "title.guestForm": "Ihr Profil",
  "placeholder.guestForm.name": "Ihr Name oder Spitzname",
  "message.guestForm.nameRequired": "Name ist erforderlich",
  "message.guestForm.dataInfo":
    "Die angegebenen Daten sind nicht obligatorisch. Sie werden nur als Signatur auf den Fotos und Videos angezeigt, die Sie senden.",
  "button.guestForm.takePhoto": "Mach ein Selfie (optional)",
  "button.guestForm.submit": "Speichern",
  "button.guestForm.skip": "Anonym fortfahren",
  "message.cameraNotAvailable":
    "Die Kamera konnte nicht zugegriffen werden. Bitte stellen Sie sicher, dass Sie die Berechtigungen für die Kamera erteilt haben.",

  "title.photoChallenge": "Foto-Herausforderung",
  "subtitle.photoChallenge.tasks": "Aufgaben",
  "subtitle.photoChallenge.done": "Erledigt",
  "message.photoChallenge.intro":
    "Das Ziel ist es, Fotos gemäß den angegebenen Zielen zu machen.",
  "message.photoChallenge.todo": "Machen Sie eine Herausforderung",
  "message.photoChallenge.done": "Herausforderungen abgeschlossen",
  "button.photoChallenge.start": "Starten",

  "title.quiz": "Quiz",
  "subtitle.quiz.intro": "Nehmen Sie die Herausforderung an?",
  "subtitle.quiz.result": "Ergebnis",
  "message.quiz.intro":
    "Beweisen Sie unter allen Gästen, dass Sie die Person sind, die das Paar am besten kennt!",
  "button.quiz.start": "Starten",
  "message.quiz.result":
    "Sie haben {score} von {totalQuestions} Fragen in {time} Sekunden richtig beantwortet",
  "message.quiz.saving": "Speichern...",

  "title.teamMatch": "Team-Match",
  "subtitle.teamMatch.intro": "Entdecken Sie Ihr Team",
  "subtitle.teamMatch.result": "Ergebnis",
  "message.teamMatch.intro":
    "Machen Sie den Test und finden Sie heraus, ob Sie zum Team {teamA} oder zum Team {teamB} gehören!",
  "button.teamMatch.start": "Starten",
  "message.teamMatch.result": "Ihr Team ist <code>{team}</code>!",
  "message.teamMatch.saving": "Speichern...",

  "feed.loading": "Feed wird geladen...",
  "feed.empty": "Noch keine Elemente im Feed.",
  "feed.imageAlt": "Hochgeladenes Bild",
  "feed.anonymousGuest": "Anonymer Gast",
  "feed.imageUploader.title": "Hochgeladenes Foto",
  "feed.imageUploader.description": "{guestName} hat ein Foto hochgeladen",
  "feed.quiz.title": "Quiz",
  "feed.quiz.description":
    "{guestName} hat das Quiz abgeschlossen! Ergebnis: {score}/{total} in {time} Sekunden",
  "feed.photoChallenge.title": "Foto-Herausforderung",
  "feed.photoChallenge.description":
    "{guestName} hat die Herausforderung abgeschlossen: {challenge}",
  "feed.teamMatch.title": "Team-Match",
  "feed.teamMatch.description":
    "{guestName} hat am Team-Match teilgenommen. Ergebnis: {teamAName} {scoreA} x {scoreB} {teamBName}",
  "feed.newGuest.title": "Neuer Gast",
  "feed.newGuest.description": "{guestName} ist dem Event beigetreten",
  "feed.videoRecorder.title": "Neue Nachricht",
  "feed.videoRecorder.description":
    "{guestName} hat eine neue Nachricht gesendet",
  "feed.default.title": "Aktivität",
  "feed.default.description": "Eine neue Aktivität ist aufgetreten",
};

export default message;
