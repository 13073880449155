import { CameraOn, Close, Heart, Trash } from "assets/svg";
import BaseModal from "components/BaseModal";
import PhotoTaker from "components/PhotoTaker/PhotoTaker";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useEventStore } from "store/useEventStore";

const data = {
  1: {
    title: "Tire uma foto com o pai da noiva",
    description: "Tire uma foto com o pai da noiva",
  },
  2: {
    title: "Tire uma foto com o pai do noivo",
    description: "Tire uma foto com o pai do noivo",
  },
  3: {
    title: "Tire uma foto com a mãe da noiva",
    description: "Tire uma foto com a mãe da noiva",
  },
  4: {
    title: "Tire uma foto com a mãe do noivo",
    description: "Tire uma foto com a mãe do noivo",
  },
  5: {
    title: "Tire uma foto com os noivos",
    description: "Tire uma foto com os noivos",
  },
  6: {
    title: "Tire uma foto com um casal de padrinhos",
    description: "Tire uma foto com um casal de padrinhos",
  },
  7: {
    title: "Tire uma foto dançando",
    description: "Tire uma foto dançando",
  },
  8: {
    title: "Tire uma foto com alguém que não conhecia antes",
    description: "Tire uma foto com alguém que não conhecia antes",
  },
  9: {
    title: "Tire uma foto com o buquê",
    description: "Tire uma foto com o buquê",
  },
  10: {
    title: "Tire uma foto com três gerações juntas",
    description: "Tire uma foto com avó, mãe e filha juntas",
  },
  11: {
    title: "Tire uma foto com as alianças",
    description: "Tire uma foto com as alianças",
  },
  12: {
    title: "Tire uma foto com a decoração mais bonita",
    description: "Tire uma foto com a decoração mais bonita",
  },
  13: {
    title: "LOVE",
    description: "",
  },
  14: {
    title: "Tire uma selfie com seu +1",
    description: "Tire uma selfie com seu acompanhante",
  },
  15: {
    title: "Tire uma foto com as crianças da festa",
    description: "Tire uma foto com as crianças da festa",
  },
  16: {
    title: "Tire uma foto com os avós dos noivos",
    description: "Tire uma foto com os avós dos noivos",
  },
  17: {
    title: "Tire uma foto com o bolo",
    description: "Tire uma foto com o bolo de casamento",
  },
  18: {
    title: "Tire uma foto com os irmãos dos noivos",
    description: "Tire uma foto com os irmãos dos noivos",
  },
  19: {
    title: "Tire uma foto do seu prato favorito",
    description: "Tire uma foto do seu prato favorito",
  },
  20: {
    title: "Tire uma foto com a mesa de doces",
    description: "Tire uma foto com a mesa de doces",
  },
  21: {
    title: "Tire uma foto do brinde",
    description: "Tire uma foto do momento do brinde",
  },
  22: {
    title: "Tire uma foto com os músicos",
    description: "Tire uma foto com a banda ou DJ",
  },
  23: {
    title: "Tire uma foto do seu sapato de dança",
    description: "Tire uma foto do seu sapato na pista de dança",
  },
  24: {
    title: "Tire uma foto com primos dos noivos",
    description: "Tire uma foto com os primos dos noivos",
  },
  25: {
    title: "Tire uma foto do momento mais emocionante",
    description: "Tire uma foto do momento que mais te emocionou",
  },
};

export default function Bingo() {
  const { theme } = useEventStore();
  const [step, setStep] = useState<"intro" | "game">("intro");

  const onGoToIntro = () => {
    setStep("intro");
  };

  return (
    <div
      className={`${theme} w-full flex justify-center items-center px-10 py-16`}
    >
      <div className="w-full flex flex-col justify-start items-center">
        {/* Header */}
        <div className="flex w-full items-center justify-between mb-16">
          <h1 className="m-0 p-0 grow text-center">Bingo</h1>
          {step !== "intro" && (
            <button
              className="flex items-center text-primary"
              onClick={onGoToIntro}
            >
              <span className="text-lg">
                <Close width={36} height={36} color="none" outline="#414141" />
              </span>
            </button>
          )}
        </div>
        {step === "intro" && <Intro onStartClick={() => setStep("game")} />}
        {step === "game" && <Game data={data} />}
      </div>
    </div>
  );
}

const Intro = ({ onStartClick }: { onStartClick: () => void }) => {
  const { theme, event } = useEventStore();

  const onBackHome = useCallback(() => {
    window.location.href = `/?code=${event?.code}`;
  }, [event?.code]);

  return (
    <div className="flex flex-col justify-center items-center gap-16">
      <h2 className="text-2xl font-medium">Bingo da Festa</h2>
      <p className="text-lg text-gray-500">
        Complete o bingo para ganhar uma surpresa!
      </p>
      <div className="w-full flex flex-col justify-center items-center gap-8">
        <button
          onClick={onStartClick}
          className={`w-full px-4 py-3 ${theme.button}`}
        >
          <span className="text-2xl text-center w-full">Iniciar</span>
        </button>
        <button
          className="py-3 px-10 underline text-lg"
          type="button"
          onClick={onBackHome}
        >
          <FormattedMessage
            id="button.recorder.home"
            defaultMessage="Retornar à tela inicial"
          />
        </button>
      </div>
    </div>
  );
};

const Game = ({
  data,
}: {
  data: { [key: number]: { title: string; description: string } };
}) => {
  const { theme } = useEventStore();
  const [activeSlot, setActiveSlot] = useState<number | null>(null);
  const [takenPhotos, setTakenPhotos] = useState<{ [key: number]: string }>({});
  const [showRemoveAlert, setShowRemoveAlert] = useState<boolean>(false);
  const [firstCompleteRow, setFirstCompleteRow] = useState<{
    rows: number[];
    dateTime: Date;
  } | null>(null);
  const [firstCompleteCol, setFirstCompleteCol] = useState<{
    cols: number[];
    dateTime: Date;
  } | null>(null);
  const [fullCard, setFullCard] = useState<{ dateTime: Date } | null>(null);

  const onPhotoTaken = (photo: string) => {
    if (!activeSlot) return;
    const newTakenPhotos = { ...takenPhotos, [activeSlot]: photo };
    setTakenPhotos(newTakenPhotos);
    if (!firstCompleteRow) {
      console.log(`takenPhotos`, newTakenPhotos);
      calculateAndAssignRow({
        takenPhotos: newTakenPhotos,
        setFirstCompleteRow,
      });
    }
    if (!firstCompleteCol) {
      calculateAndAssignCol({
        takenPhotos: newTakenPhotos,
        setFirstCompleteCol,
      });
    }
    if (!fullCard) {
      calculateAndAssignFullCard({
        takenPhotos: newTakenPhotos,
        setFullCard,
      });
    }
  };

  const onCheckRemovePhoto = () => {
    setShowRemoveAlert(true);
  };

  const onRemovePhoto = () => {
    if (!activeSlot) return;
    const newState = { ...takenPhotos };
    delete newState[activeSlot];
    setTakenPhotos(newState);

    const { isInFirstCompleteRow, isInFirstCompleteCol } = checkRemovedPhoto({
      firstCompleteRow,
      firstCompleteCol,
      activeSlot,
    });

    if (isInFirstCompleteRow) {
      setFirstCompleteRow(null);
      calculateAndAssignRow({
        takenPhotos: newState,
        setFirstCompleteRow,
      });
    }
    if (isInFirstCompleteCol) {
      setFirstCompleteCol(null);
      calculateAndAssignCol({
        takenPhotos: newState,
        setFirstCompleteCol,
      });
    }
    setFullCard(null);
  };
  console.log("takenPhotos", Object.keys(takenPhotos).length);
  console.log("firstCompleteRow", firstCompleteRow);

  return (
    <>
      <BaseModal
        isOpen={showRemoveAlert}
        onClose={() => setShowRemoveAlert(false)}
      >
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="flex flex-col items-center justify-center gap-4">
            <h3 className="text-base text-center font-medium">
              Ao excluir este desafio, seus resultados serão recalculados.
            </h3>
            <ul className="list-disc pl-4">
              <li>Linhas e colunas afetadas pela exclusão serão revisadas</li>
              <li>
                Se houver outras conquistas completas, o horário será atualizado
                para o momento atual
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-between gap-2 w-full">
            <button className={theme.buttonSecondary} onClick={onRemovePhoto}>
              Excluir
            </button>
            <button
              className={theme.button}
              onClick={() => setShowRemoveAlert(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </BaseModal>
      <ChallengeSection
        activeSlot={activeSlot}
        data={data}
        takenPhotos={takenPhotos}
        onPhotoTaken={onPhotoTaken}
        onCheckRemovePhoto={onCheckRemovePhoto}
      />
      <div className="w-full h-full flex flex-col items-start justify-center gap-10">
        <BingoBoard
          data={data}
          takenPhotos={takenPhotos}
          activeSlot={activeSlot}
          setActiveSlot={setActiveSlot}
        />
        <ScoresSection
          firstCompleteRow={firstCompleteRow}
          firstCompleteCol={firstCompleteCol}
          fullCard={fullCard}
        />
      </div>
    </>
  );
};

const BingoBoard = ({
  data,
  takenPhotos,
  activeSlot,
  setActiveSlot,
}: {
  data: { [key: number]: { title: string; description: string } };
  takenPhotos: { [key: number]: string };
  activeSlot: number | null;
  setActiveSlot: (slot: number) => void;
}) => {
  const { theme } = useEventStore();
  return (
    <div className="grid grid-cols-5 gap-2 w-full aspect-square">
      {Object.entries(data).map(([key, value]) => {
        if (key === "13") {
          return (
            <div
              key={key}
              className={`w-full h-full rounded-md flex items-center justify-center ${theme.box} aspect-square`}
            >
              <Heart width={30} height={30} color="none" outline="#fff" />
            </div>
          );
        }
        return (
          <div
            onClick={() => setActiveSlot(Number(key))}
            key={key}
            className={`w-full h-full rounded-md flex items-center justify-center leading-6 aspect-square ${
              takenPhotos[Number(key)] ? theme.box : theme.boxSecondary
            }
              `}
          >
            <span
              className={`border-2 rounded-md min-h-[90%] min-w-[90%] flex items-center justify-center aspect-square ${
                activeSlot === Number(key)
                  ? takenPhotos[Number(key)]
                    ? "border-white font-century-gothic-bold"
                    : "border-[#F76C6F] font-century-gothic-bold"
                  : "border-transparent"
              }`}
            >
              {key}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const ChallengeSection = ({
  activeSlot,
  data,
  takenPhotos,
  onPhotoTaken,
  onCheckRemovePhoto,
}: {
  activeSlot: number | null;
  data: { [key: number]: { title: string; description: string } };
  takenPhotos: { [key: number]: string };
  onPhotoTaken: (photo: string) => void;
  onCheckRemovePhoto: () => void;
}) => {
  const { theme } = useEventStore();
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-2 pb-8">
      {!activeSlot && (
        <h3 className="text-lg font-medium">
          Clique para ver e realizar o desafio
        </h3>
      )}
      {activeSlot && (
        <>
          {takenPhotos[activeSlot] ? (
            <div
              className={`flex relative h-48 justify-between w-full gap-2 bg-white rounded-lg p-2`}
            >
              <div className="flex flex-col items-center justify-between">
                <div className="flex justify-start w-full">
                  <span className="font-century-gothic-bold text-lg text-white bg-[#F76C6F] rounded-xl h-10 w-10 flex items-center justify-center grow-0 shrink-0">
                    {activeSlot}
                  </span>
                </div>
                <span className="text-base text-left text-gray-600">
                  {data[activeSlot].title}
                </span>
                <button
                  className={`${theme.buttonSecondary} flex items-center justify-center gap-2 w-full`}
                  onClick={onCheckRemovePhoto}
                >
                  <Trash
                    width={20}
                    height={20}
                    color="none"
                    outline="#F76C6F"
                  />{" "}
                  <span className="text-sm">Remover Desafio</span>
                </button>
              </div>
              <img
                src={takenPhotos[activeSlot]}
                alt="Foto realizada"
                className="h-full object-cover rounded-lg"
              />
            </div>
          ) : (
            <PhotoTaker
              onPhotoTaken={onPhotoTaken}
              startButtonClassNames={`${theme.buttonSecondary} p-6 w-full`}
            >
              <div className="w-full flex items-center justify-center gap-4">
                <CameraOn
                  width={30}
                  height={30}
                  color="none"
                  outline="#F76C6F"
                />
                <span className="text-sm text-left">{`${activeSlot} - ${data[activeSlot].title}`}</span>
              </div>
            </PhotoTaker>
          )}
        </>
      )}
    </div>
  );
};

const ScoresSection = ({
  firstCompleteRow,
  firstCompleteCol,
  fullCard,
}: {
  firstCompleteRow: { rows: number[]; dateTime: Date } | null;
  firstCompleteCol: { cols: number[]; dateTime: Date } | null;
  fullCard: { dateTime: Date } | null;
}) => {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-2">
      <h3 className="text-lg font-medium">Resultados</h3>
      <div className="w-full flex items-center justify-between gap-2">
        <h4 className="text-base font-medium">
          1ª - Linha {firstCompleteRow?.rows.join(", ")}
        </h4>
        <h4 className="text-base font-medium text-right">
          {firstCompleteRow && formatDateTime(firstCompleteRow?.dateTime)}
        </h4>
      </div>
      <div className="w-full flex items-center justify-between gap-2">
        <h4 className="text-base font-medium">
          1ª - Coluna {firstCompleteCol?.cols.join(", ")}
        </h4>
        <h4 className="text-base font-medium text-right">
          {firstCompleteCol && formatDateTime(firstCompleteCol?.dateTime)}
        </h4>
      </div>
      <div className="w-full flex items-center justify-between gap-2">
        <h4 className="text-base font-medium">Cartela Completa</h4>
        <h4 className="text-base font-medium text-right">
          {fullCard && formatDateTime(fullCard?.dateTime)}
        </h4>
      </div>
    </div>
  );
};

const calculateAndAssignRow = ({
  takenPhotos,
  setFirstCompleteRow,
}: {
  takenPhotos: { [key: number]: string };
  setFirstCompleteRow: (row: { rows: number[]; dateTime: Date }) => void;
}) => {
  console.log("Calculating row");
  // if takenPhotos has one of the options below:
  // 1, 2, 3, 4, 5
  // 6, 7, 8, 9, 10
  // 11, 12, 14, 15
  // 16, 17, 18, 19, 20
  // 21, 22, 23, 24, 25
  // return the array of numbers that represent the row and the time
  // if not, return null
  const rows = [
    [1, 2, 3, 4, 5],
    [6, 7, 8, 9, 10],
    [11, 12, 14, 15],
    [16, 17, 18, 19, 20],
    [21, 22, 23, 24, 25],
  ];

  console.log("Checking rows:", rows);
  const row = rows.find((row) => {
    console.log("Checking row:", row);
    const hasAllPhotos = row.every((num) => {
      console.log(
        `Checking number ${num}:`,
        takenPhotos[num] ? "true" : "false"
      );
      return takenPhotos[num];
    });
    console.log("Row complete:", hasAllPhotos);
    return hasAllPhotos;
  });
  console.log("Found row:", row);
  const rowResult = row ? { rows: row, dateTime: new Date() } : null;
  if (rowResult) {
    console.log("Setting row:", rowResult);
    setFirstCompleteRow(rowResult);
  }
};

const calculateAndAssignCol = ({
  takenPhotos,
  setFirstCompleteCol,
}: {
  takenPhotos: { [key: number]: string };
  setFirstCompleteCol: (col: { cols: number[]; dateTime: Date }) => void;
}) => {
  const cols = [
    [1, 6, 11, 16, 21],
    [2, 7, 12, 17, 22],
    [3, 8, 18, 23],
    [4, 9, 14, 19, 24],
    [5, 10, 15, 20, 25],
  ];

  const col = cols.find((col) => col.every((num) => takenPhotos[num]));
  const colResult = col ? { cols: col, dateTime: new Date() } : null;
  if (colResult) {
    console.log("Setting col:", colResult);
    setFirstCompleteCol(colResult);
  }
};

const calculateAndAssignFullCard = ({
  takenPhotos,
  setFullCard,
}: {
  takenPhotos: { [key: number]: string };
  setFullCard: (fullCard: { dateTime: Date }) => void;
}) => {
  const allNumbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25,
  ];

  const fullCard = allNumbers.every((num) => takenPhotos[num]);
  const fullCardResult = fullCard ? { dateTime: new Date() } : null;
  if (fullCardResult) {
    console.log("Setting full card:", fullCardResult);
    setFullCard(fullCardResult);
  }
};

// Format to dd/mm - hh:mm:ss
const formatDateTime = (date: Date) => {
  return date.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

const checkRemovedPhoto = ({
  firstCompleteRow,
  firstCompleteCol,
  activeSlot,
}: {
  firstCompleteRow: { rows: number[]; dateTime: Date } | null;
  firstCompleteCol: { cols: number[]; dateTime: Date } | null;
  activeSlot: number;
}) => {
  const isInFirstCompleteRow = firstCompleteRow
    ? firstCompleteRow?.rows.includes(activeSlot)
    : false;
  const isInFirstCompleteCol = firstCompleteCol
    ? firstCompleteCol?.cols.includes(activeSlot)
    : false;

  return {
    isInFirstCompleteRow,
    isInFirstCompleteCol,
  };
};
